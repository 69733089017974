import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import { graphql } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Resume from './Resume';
import Timeline from './Timeline';
import Container from '../../components/block/Container';

const ResumePage = ({ data, intl: { locale } }) => {
  const resumePosts = data[`shortCV${locale}`].edges;
  const timelinePosts = data[`longCV${locale}`].edges;
  return (
    <Layout>
      <SEO title="Cyril Planchon" />
      <Container>
        <Resume data={resumePosts} />
      </Container>
      <Timeline data={timelinePosts} />
    </Layout>
  );
};

ResumePage.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    allContentfulBlogPost: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    fr: PropTypes.shape({
      edges: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default injectIntl(ResumePage);

export const query = graphql`
  query ResumePageQuery {
    shortCVen: allContentfulShortCv(filter: {node_locale: {eq: "en-US"}}, sort: {order: DESC, fields: orderNumber}) {
      edges {
        node {
          id
          description
          date
        }
      }
    },
    shortCVfr: allContentfulShortCv(filter: {node_locale: {eq: "fr"}}, sort: {order: DESC, fields: orderNumber}) {
      edges {
        node {
          id
          description
          date
        }
      }
    },
  longCVen: allContentfulLongCv(filter: {node_locale: {eq: "en-US"}}, sort: {order: DESC, fields: orderNumber}) {
    edges {
      node {
        id
        description {
          json
        }
        title
      }
    }
  },
  longCVfr: allContentfulLongCv(filter: {node_locale: {eq: "fr"}}, sort: {order: DESC, fields: orderNumber}) {
    edges {
      node {
        id
        description {
          json
        }
        title
      }
    }
  },
  }
`;
